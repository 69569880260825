/* eslint-disable camelcase */
import tinymce from "tinymce/tinymce";
import "tinymce/skins/ui/oxide/skin.min.css";
import "tinymce/icons/default/icons";
import "tinymce/themes/silver/theme";
import "tinymce/models/dom/model";
import "tinymce/plugins/lists";
import "tinymce/plugins/link";
import "tinymce/plugins/table";
import "./tinymce-plugin.js";

document.addEventListener("turbo:before-visit", function () {
  // Remove all previous instances of tinymce this is due to turbo pages.
  tinymce.remove();
});

document.addEventListener("turbo:load", () => {
  let defaultSetup = {
    selector: ".tinymce-normal",
    content_css: false,
    skin: false,
    statusbar: false,
    menubar: "",
    branding: false,
    promotion: false,

    // enable inserting link with tinymce
    pad_empty_with_br: true,

    plugins: "wordpaste lists link",
    content_style: "body { font-family: Helvetica Neue,Helvetica,Arial,sans-serif; font-size: 14px; }",

    toolbar: "styles | bold italic underline | link | bullist numlist | removeformat",
    style_formats: [
      { title: "Overskrift 1", format: "h1" },
      { title: "Overskrift 2", format: "h2" },
      { title: "Overskrift 3", format: "h3" },
      { title: "Normal", format: "p" }
    ]
  };
  tinymce.init(defaultSetup);
});
