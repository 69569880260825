import tinymce from "tinymce";

tinymce.PluginManager.add("wordpaste", function (editor) {
  const pasteCleanText = function () {
    let content = editor.getContent({ format: "text" });
    editor.setContent(cleanContent(content));
  };
  editor.ui.registry.addButton("cleanbtn", {
    icon: "remove-formatting",
    tooltip: "Fjern formattering",
    onAction: function () {
      pasteCleanText();
    }
  });

  /* Return the metadata for the help plugin */
  return {
    getMetadata: function () {
      return {
        name: "Word Paste Plugin",
        url: "https://www.medware.dk/"
      };
    }
  };
});

function cleanContent(input) {
  // 1. remove line breaks / Mso classes
  const stringStripper = /(\n|\r| class=(")?Mso[a-zA-Z]+(")?)/g;
  let output = input.replace(stringStripper, " ");
  // 2. strip Word generated HTML comments
  const commentSripper = new RegExp("<!--(.*?)-->", "g");
  output = output.replace(commentSripper, "");
  let tagStripper = new RegExp("<(/)*(meta|link|div|pre|span|\\?xml:|st1:|o:|font)(.*?)>", "gi");
  // 3. remove tags leave content if any
  output = output.replace(tagStripper, "");
  // 4. Remove everything in between and including tags '<style(.)style(.)>'
  const badTags = ["style", "script", "applet", "embed", "noframes", "noscript"];

  for (let i = 0; i < badTags.length; i++) {
    tagStripper = new RegExp("<" + badTags[i] + ".*?" + badTags[i] + "(.*?)>", "gi");
    output = output.replace(tagStripper, "");
  }
  // 5. remove attributes ' style="..."'
  const badAttributes = ["style", "start"];
  for (let i = 0; i < badAttributes.length; i++) {
    const attributeStripper = new RegExp(" " + badAttributes[i] + '="(.*?)"', "gi");
    output = output.replace(attributeStripper, "");
  }
  return output;
}
